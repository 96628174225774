import { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";

// Used to generate UUIDs
import { v4 as uuidv4 } from "uuid";

// Used to retrieve the latest version string
import axios from "axios";

// Used to check current app version
import packageJson from "../package.json";

// Only load relevant FontAwesome icons
import "setupIcons";

// Mitt is used to emit events from bottom level components to this component e.g. for showing toasts
import mitt from "mitt";

import config from "config";
import * as utils from "utils/utils";
import * as localRoleUtils from "utils/roleUtils";
import * as authUtils from "utils/authUtils";

// Custom page loader component
import MaintenancePage from "pages/auth/MaintenancePage";
import PageLayout from "components/PageLayout";
import {
  Modal,
  Button,
  Loader,
  Message,
  roleUtils,
  generalUtils,
  createStore,
  withStore,
  numberUtils,
  timerUtils,
  withError
} from "bob-group-ui-framework";
import AccountClosedPage from "pages/auth/AccountClosedPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IResult } from "interfaces/result.interface";
import rg4js, { RaygunPayload } from "raygun4js";
import { IStore } from "interfaces/store.interface";

const { title } = config;
const emitter = mitt();

interface IProps {
  store: IStore;
  history: any;
  location: any;
}

function App(props: IProps) {
  const { store, history, location } = props;

  const [toasts, setToasts] = useState<any>([]);
  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(true);
  const [error, setError] = useState<any>(undefined);
  const [alertModal, setAlertModal] = useState<any>(null);
  const [newVersionAvailable, setNewVersionAvailable] = useState<boolean>(false);
  const [isDesktop, setIsDesktop] = useState<boolean>(window.innerWidth >= 992);
  const isNavbarCollapsedFromStorage = localStorage.getItem("is_navbar_collapsed");
  const [isNavbarCollapsed, setIsNavbarCollapsed] = useState<boolean>(
    Boolean(isNavbarCollapsedFromStorage && JSON.parse(isNavbarCollapsedFromStorage))
  );
  const [showNav, setShowNav] = useState<boolean>(false);
  const [sectionsLoaded, setSectionsLoaded] = useState<any>({
    desktopLogo: false,
    mobileLogo: false,
    navItems: false
  });
  const [token, setToken] = useState<any>(undefined);
  const [shouldShowLoggingOut, setShouldShowLoggingOut] = useState<boolean>(false);

  const storeRef: any = useRef(undefined);

  useEffect(() => {
    storeRef.current = store;
  }, [store]);

  useEffect(() => {
    const refreshRandomiser = numberUtils.getRandomPrimeBetween(0, 300);
    const refreshInterval = 60 * 60 * 1000 + refreshRandomiser;
    localStorage.setItem("isRefreshing", "false");

    utils.checkTime(store);

    // Ensures the navbar is not in collapsed mode whenever switching to mobile view
    window.addEventListener("resize", () => {
      const isNavbarCollapsedFromStorage = localStorage.getItem("is_navbar_collapsed");

      if (window.innerWidth < 992) {
        setIsDesktop(false);
        setIsNavbarCollapsed(false);
      } else {
        setIsDesktop(true);
        setIsNavbarCollapsed(
          Boolean(isNavbarCollapsedFromStorage && JSON.parse(isNavbarCollapsedFromStorage))
        );
      }
    });

    timerUtils.setIntervalAccurately(
      "getAccount",
      () => {
        if (store.inFocus) {
          getAccount(true);
        }
      },
      5 * 60 * 1000
    );

    timerUtils.setIntervalAccurately(
      "checkPackageVersion",
      async () => {
        checkPackageVersion();
      },
      20 * 60 * 1000
    );

    timerUtils.setIntervalAccurately(
      "getGeneralData",
      async () => {
        if (
          authUtils.getAccessToken() &&
          utils.activeUserFromStore(storeRef.current) !== undefined
        ) {
          // @ts-ignore
          await authUtils.refreshToken(
            storeRef.current,
            utils.activeUserFromStore(storeRef.current)
          );
        }
        if (authUtils.getAccessToken()) {
          await getGeneralData(true);
        }
      },
      refreshInterval
    );

    createRaygunLogger();
    setupEmitter();
    checkMaintenanceMode();

    document.title = title;

    store.set("inFocus", true);

    window.addEventListener("focus", () => {
      store.set("inFocus", true);
    });

    window.addEventListener("blur", () => store.set("inFocus", false));

    checkUserHasAuthenticated();
    return () => {
      emitter.off("showToast", showToast);
    };
  }, []);

  async function checkUserHasAuthenticated() {
    if (authUtils.getAccessToken()) {
      await userHasAuthenticated(true);
    } else {
      await userHasAuthenticated(false);
      await getSystemConfig();
      setIsAuthenticating(false);
    }

    checkPackageVersion();
    utils.checkBrowserVersion(store);
  }

  function createRaygunLogger() {
    rg4js("apiKey", config.raygunKey);
    rg4js("enableCrashReporting", true);
    rg4js("setVersion", `${packageJson.version}`);
    rg4js("enablePulse", false);
    rg4js("options", {
      // Remove if you want to have it working on dev as well
      excludedHostnames: ["localhost", "127.0.0.1:9100", "127.0.0.1"],
      // Will include later if 3rd party not working.
      // CaptureUnhandledRejections: false,
      ignore3rdPartyErrors: true
    });
    rg4js("logContentsOfXhrCalls", true);
    const myBeforeSend = function (payload: RaygunPayload) {
      if (payload.Details.Error.Message.includes("Failed to write to device")) {
        return false;
      }
      return payload;
    };
    rg4js("onBeforeSend", myBeforeSend);

    rg4js("withTags", [`${packageJson.version}`, "front-end"]);
  }

  function setupEmitter() {
    store.set("emitter", emitter);
    emitter.on("showToast", showToast);
    emitter.on("showAlert", showAlert);
    emitter.on("refreshAccount", () => getAccount(false));
    emitter.on("accountClosed", accountClosed);
    emitter.on("logOut", handleLogout);
    emitter.on("maintenanceMode", enterMaintenanceMode);
    emitter.on("changeNavbarCollapsedValue", changeNavbarCollapsedValue);
  }

  function changeNavbarCollapsedValue(val: any) {
    localStorage.setItem("is_navbar_collapsed", JSON.stringify(Boolean(val)));
    setIsNavbarCollapsed(val);
  }
  function enterMaintenanceMode() {
    const location = window.location.pathname;
    let q = `?r=${location}`;
    if (location === "/maintenance") {
      q = window.location.search;
    }
    history.push("/maintenance" + q);
    setTimeout(() => {
      window.location.reload();
    }, 5 * 60000 + Math.round(Math.random() * 60000)); // 5 minutes plus a random minute
  }

  function checkMaintenanceMode() {
    const location = window.location.pathname;
    if (location === "/maintenance") {
      const urlParams = new URLSearchParams(window.location.search);
      let redirect = urlParams.get("r");
      redirect = redirect ? redirect : "/";

      history.push(redirect);
    }
  }

  async function checkPackageVersion() {
    if (window.location.hostname === "localhost") return;
    try {
      const response = await axios.get(window.location.origin + "/version.json");

      if (response.status === 200) {
        const semver = require("semver");

        if (semver.gt(response.data.version, packageJson.version)) {
          console.log("New version available");
          setNewVersionAvailable(true);
          const diff = semver.diff(response.data.version, packageJson.version);
          if (diff === "major" || diff === "minor") {
            // Version x.y.z -> fires on x (major) or y (minor) changes, but not z (patch)
            store.emitter.emit("showAlert", {
              title: "New version available",
              body: (
                <div className="space-y-4 text-center">
                  <div className="flex items-center justify-center mt-2">
                    <div className="rounded-full h-20 w-20 bg-yellow-light flex items-center justify-center ">
                      <FontAwesomeIcon icon="smile" className="text-yellow" size="3x" />
                    </div>
                  </div>
                  <div>A new version of the app is available.</div>
                  <div>
                    <strong>Please refresh</strong> to make sure your app is up to date with all the
                    awesome features!
                  </div>
                </div>
              ),
              showOkButton: true,
              showCancelButton: true,
              okButtonText: "Refresh now",
              okButtonVariant: "primary",
              cancelButtonText: "I'll refresh later",
              disableClickOutsideToClose: true,
              return: async (confirm: boolean) => {
                if (confirm) {
                  location.reload();
                }
              }
            });
          }
        } else {
          setNewVersionAvailable(false);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function accountClosed() {
    history.push("/account-closed");
  }

  async function getGeneralData(shouldIgnore401: boolean) {
    const { data, error } = await utils.getGeneralData(storeRef.current, shouldIgnore401);

    if (error) {
      setError(error);
    }
    const userSettings: any = {};

    if (data.user_settings) {
      data.user_settings.forEach((setting: any) => {
        try {
          userSettings[setting.setting] = JSON.parse(setting.value);
        } catch (e) {
          userSettings[setting.setting] = setting.value;
        }
      });
    }

    store.set("system_config", data.config ? data.config : []);
    store.set("user_settings", userSettings);
    store.set("user", data.user);
    store.set("account_settings", data.account_settings ? data.account_settings : {});
    store.set("account", data.account);
    store.set("store_plugins_installed", data.store_plugins_installed);

    const cacheData = store.cache ?? {};
    cacheData["roles"] = data.roles ?? [];
    cacheData["accountTags"] = data.account_tags ?? [];
    store.set("cache", { ...cacheData });

    return { error, data };
  }

  async function getSystemConfig() {
    const result: IResult = await utils.signedRequest(store, "/config", "GET", null, null);
    if (result.ok) {
      store.set("system_config", result.data.config ? result.data.config : []);
    } else {
      setError(generalUtils.getError(result));
    }
  }

  async function getAccount(shouldIgnore401: boolean) {
    const { account, error } = await utils.getAccount(storeRef.current, undefined, shouldIgnore401);

    if (!account && !error) return;

    if (account) {
      storeRef.current.set("account", account);
    }
  }

  async function userHasAuthenticated(
    authenticated: any,
    latestRoleResponse?: any,
    shouldShowLoggingOut?: boolean
  ) {
    setShouldShowLoggingOut(Boolean(shouldShowLoggingOut));

    const token = null;

    // Valid user
    if (authenticated) {
      const urlParams = new URLSearchParams(window.location.search);
      let impersonatedUserID = urlParams.get("impersonate_id");
      if (impersonatedUserID) {
        store.set("impersonated_user_id", impersonatedUserID);
        localStorage.setItem("impersonated_user_id", impersonatedUserID);
      } else {
        impersonatedUserID = localStorage.getItem("impersonated_user_id");
        if (impersonatedUserID) {
          try {
            store.set("impersonated_user_id", impersonatedUserID);
          } catch (e) {
            console.log(e);
          }
        }
      }
      const { data, error } = await getGeneralData(false);
      if (!error) {
        latestRoleResponse = data.roles;

        roleUtils.fillRole(data.user, latestRoleResponse, store);
        store.set("logged_in_user", data.user);
      } else {
        store.set("impersonated_user_id", undefined);
        store.set("logged_in_user", undefined);
      }
    } else {
      await getSystemConfig();
      store.set("impersonated_user_id", undefined);
      store.set("account", undefined);
      store.set("logged_in_user", undefined);
      store.set("user_settings", undefined);
    }
    setIsAuthenticating(false);
    setToken(token);
    if (authenticated) {
      getAccount(true);
      const urlParams = new URLSearchParams(window.location.search);
      let redirect = urlParams.get("redirect");

      if (redirect && redirect?.indexOf("shopify/authorised") > -1) {
        redirect = window.location.search.split("?redirect=")[1];
      }

      if (redirect) {
        history.push(redirect);
      } else if (
        window.location.pathname.indexOf("/login") >= 0 ||
        window.location.pathname.indexOf("/register") >= 0
      ) {
        history.push("/");
      }
    }
  }

  async function handleLogout(refresh: any) {
    userHasAuthenticated(false, undefined, true);
    // On logout clear cookies
    sessionStorage.clear();
    localStorage.clear();

    if (refresh || location.pathname === "/pay") {
      window.location.reload();
    } else {
      let locationString = "/";
      if (localRoleUtils.isStaff(utils.activeUser(props)) && location.pathname !== "/logout") {
        locationString = `/login?redirect=${location.pathname}${location.search}`;
      }

      window.location.href = locationString; // Using window.location.href to ensure it does not keep cached user on logout
    }
  }

  function hideToast(uuid: any) {
    setToasts((toasts: any) => {
      return generalUtils.clone(toasts.filter((toast: any) => toast.uuid !== uuid));
    });
  }

  function showToast(message: any) {
    message.uuid = uuidv4();

    setToasts((toasts: any) => {
      return generalUtils.clone([...toasts, message]);
    });

    if (message.autoHide && message.autoHide > 0) {
      setTimeout(() => hideToast(message.uuid), message.autoHide);
    }
  }

  function showAlert(alertObject: any) {
    setAlertModal(alertObject);
  }

  function onNavSectionReady(property: any) {
    sectionsLoaded[property] = true;
    setSectionsLoaded({ ...sectionsLoaded });

    let isAllReady = true;
    Object.keys(sectionsLoaded).forEach(key => {
      if (!sectionsLoaded[key]) {
        isAllReady = false;
      }
    });
    setShowNav(isAllReady);
  }

  /* -------------------------------- */
  /* RENDER METHODS */
  /* -------------------------------- */

  function renderAlertModal() {
    if (!alertModal) return;

    return (
      alertModal && (
        <Modal.Small
          disableClickOutsideToClose
          {...alertModal}
          showCloseButton={false}
          onHide={() => setAlertModal(null)}
        >
          {alertModal.body}
          <div
            className={
              (alertModal.showCancelButton ? "justify-between" : "justify-center") + " flex mt-8"
            }
          >
            {alertModal.showCancelButton ? (
              <Button.Cancel
                id="modal_cancel_button"
                onClick={() => {
                  alertModal.return(false);
                  setAlertModal(null);
                }}
                title={alertModal.cancelButtonText ? alertModal.cancelButtonText : "Cancel"}
              />
            ) : (
              <div></div>
            )}
            {alertModal.showOkButton && alertModal.okButtonVariant === "danger" && (
              <Button.Danger
                id="confirm_button_modal"
                onClick={() => {
                  alertModal.return(true);
                  setAlertModal(null);
                }}
                title={alertModal.okButtonText ? alertModal.okButtonText : "OK"}
              />
            )}
            {alertModal.showOkButton && alertModal.okButtonVariant === "primary" && (
              <Button.Primary
                onClick={() => {
                  alertModal.return(true);
                  setAlertModal(null);
                }}
                title={alertModal.okButtonText ? alertModal.okButtonText : "OK"}
              />
            )}
            {alertModal.showOkButton &&
              alertModal.okButtonVariant !== "danger" &&
              alertModal.okButtonVariant !== "primary" && (
                <Button.Cancel
                  id="confirm_button_modal"
                  onClick={() => {
                    alertModal.return(true);
                    setAlertModal(null);
                  }}
                  title={alertModal.okButtonText ? alertModal.okButtonText : "OK"}
                />
              )}
          </div>
        </Modal.Small>
      )
    );
  }
  function renderToasts() {
    if (toasts.length === 0) return;

    return (
      <div className="toasts">
        {toasts.map((toast: any) => {
          const content = (
            <div
              className="cursor-pointer"
              onClick={() => {
                hideToast(toast.uuid);
              }}
            >
              {toast.text}
            </div>
          );

          if (toast.variant === "error") {
            return (
              <Message.Error showShadow key={toast.uuid}>
                {content}
              </Message.Error>
            );
          } else if (toast.variant === "success") {
            return (
              <Message.Success showShadow key={toast.uuid}>
                {content}
              </Message.Success>
            );
          } else {
            return (
              <Message.Info showShadow key={toast.uuid}>
                {content}
              </Message.Info>
            );
          }
        })}
      </div>
    );
  }

  function render() {
    if (isAuthenticating) {
      return <Loader.Page />;
    }

    let { system_config, account_settings } = store;

    if (!system_config) {
      system_config = store.system_config;
    }
    if (!account_settings) {
      account_settings = store.account_settings;
    }

    const activeUser = utils.activeUser(props);
    const activeAccount = store.account;

    if (shouldShowLoggingOut) {
      return <Loader.Page />;
    }

    if (utils.isAccountClosedPage()) {
      return <AccountClosedPage />;
    }

    if (utils.isMaintenanceModePage()) {
      return <MaintenancePage error={error} />;
    }

    if (localRoleUtils.isAccountUserOrSuper(activeUser) && (!activeAccount || !account_settings)) {
      return <Loader.Page />;
    }

    return (
      <div>
        <PageLayout
          onNavSectionReady={onNavSectionReady}
          token={token}
          // @ts-ignore
          userHasAuthenticated={(
            authenticated: boolean,
            latestRoleResponse: any,
            showLoggingOut: boolean
          ) => {
            userHasAuthenticated(authenticated, latestRoleResponse, showLoggingOut);
          }}
          activeUser={activeUser}
          showNav={showNav}
          newVersionAvailable={newVersionAvailable}
          version={packageJson.version}
          isNavbarCollapsed={isNavbarCollapsed}
          setNavbarCollapsed={(value: any) => setIsNavbarCollapsed(value)}
          isDesktop={isDesktop}
        />
        {renderAlertModal()}
        {renderToasts()}
        {/* @ts-ignore */}
        <Modal.Host />
      </div>
    );
  }

  return render();
}

// @ts-ignore
export default createStore(withRouter(withStore(withError(App))));
